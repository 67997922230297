import React, { useEffect, useState } from "react";
import { View, ScrollView, Text, ImageBackground, SafeAreaView, TouchableOpacity, Image, Linking } from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import Header from "../components/header";
import i18n from "../components/i18n";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";
import AsyncStorage from "../components/AsyncStorage";

const MobileApp = () => {
    const navigation = useNavigation();
    const [lang, setLang] = useState("en");

    useEffect(() => {
        AsyncStorage.getData("lang").then((lang) => {
            if (lang) {
                setLang(lang)
            }
        });
    }, []);

    return (
        <View className="flex-1 md:bg-[#efefef] w-full">
            <ImageBackground source={require("../assets/login-bg.png")} resizeMode="cover" className="flex-1 justify-start items-center w-full h-full">
                <SafeAreaView className="flex-1 bg-white flex justify-start items-center w-full md:bg-transparent">
                    <NewMobileHeader />
                    <MobileHeader title={i18n.t("mobileApp.title")} />
                    <ScrollView className="w-full flex-1">
                        <Header />
                        <View className="items-center content-start">
                            <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-7 mt-6 md:mb-24 pb-10">
                                <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">{i18n.t("mobileApp.title")}</Text>

                                <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

                                <View className="w-[350px] md:w-[560px] lg:w-[700px] xl:w-[840px] flex-1 m-auto mt-6 relative">
                                    <Image source={require("../assets/mobile-app-bg.jpg")} className="w-[350px] h-[204px] md:w-[560px] md:h-[327px] lg:w-[700px] lg:h-[408px] xl:w-[840px] xl:h-[490px] m-auto" />

                                    <TouchableOpacity className="absolute top-[92px] right-[87px] md:top-[148px] md:right-[138px] lg:top-[188px] lg:right-[174px] xl:top-[219px] xl:right-[208px]" onPress={() => Linking.openURL("https://apps.apple.com/sg/app/imperial-treasure/id6462842499")}>
                                        <Image source={require("../assets/download-apple-store.png")} className="w-[55px] h-[16px] md:w-[88px] md:h-[25px] lg:w-[110px] lg:h-[31px] xl:w-[132px] xl:h-[38px] m-auto" />
                                    </TouchableOpacity>

                                    <TouchableOpacity className="absolute top-[92px] right-[32px] md:top-[148px] md:right-[54px] lg:top-[188px] lg:right-[67px] xl:top-[219px] xl:right-[77px]" onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.xgate.imperialtreasure&hl=en_SG")}>
                                        <Image source={require("../assets/download-google-play.png")} className="w-[49px] h-[16px] md:w-[78px] md:h-[25px] lg:w-[97px] lg:h-[31px] xl:w-[117px] xl:h-[38px] m-auto" />
                                    </TouchableOpacity>
                                </View>                                

                                <View className="my-6">
                                    <Text className="text-[#303030] font-semibold text-base text-center">{i18n.t("mobileApp.desc_1")}</Text>
                                    <Text className="text-[#303030] font-semibold text-base text-center">{i18n.t("mobileApp.desc_2")}</Text>

                                    <View className="w-full flex flex-row justify-center mt-6">
                                        <TouchableOpacity className="mr-3" onPress={() => Linking.openURL("https://apps.apple.com/sg/app/imperial-treasure/id6462842499")}>
                                            <Image source={require("../assets/download-apple-store.png")} className="w-[132px] h-[38px] m-auto" />
                                        </TouchableOpacity>

                                        <TouchableOpacity className="ml-3" onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.xgate.imperialtreasure&hl=en_SG")}>
                                            <Image source={require("../assets/download-google-play.png")} className="w-[117px] h-[38px] m-auto" />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                {/* <ImageBackground source={require("../assets/mobile-app-bg.jpg")} resizeMode="cover" className="flex-1 justify-start items-center w-full h-[100px]"></ImageBackground> */}
                            </View>
                        </View>

                        <Footer />
                        <CookieConsent />
                    </ScrollView>
                </SafeAreaView>
            </ImageBackground>
        </View>
    );
};

export default MobileApp;
